<template>
    <div class="mb-4">
        <div class="mb-4">
            <h4 class="font-weight-600">Quiz</h4>
        </div>
        <!-- <div class="row pb-4">
            <div v-for="mod in 6" :key="mod" class="col-sm-6 col-xl-4">
                <ModuleCard :cardData="cardData" />
            </div>
            <div
                class="
                    col-12
                    d-flex
                    justify-content-center
                    pt-4
                    pb-1
                    overflow-hidden
                "
            >
                <nav aria-label="Page navigation">
                    <ul class="pagination">
                        <li class="page-item">
                            <a class="page-link" href="#">Prev</a>
                        </li>
                        <li class="page-item">
                            <a
                                :class="{ active: isFirstPage }"
                                class="page-link"
                                href="#"
                                >1</a
                            >
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">2</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">3</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">4</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div> -->
    </div>
</template>

<script>
// import ModuleCard from "@/components/ModuleCard";
export default {
    name: "MyAccountQuiz",
    components: {
        // ModuleCard,
    },
    data() {
        return {
            cardData: {
                id: 1,
                title: "Module name",
                is_liked: 0,
                is_workshop: 1,
                img_url: require("@/assets/imgs/module.png"),
                speciality: "Anesthesiology",
                fees: "50$",
                progress: 35,
                owner: {
                    name: "Danielle Patel",
                    img: require("@/assets/imgs/owner.png"),
                },
                tags: [
                    {
                        id: 1,
                        type: "article",
                        name: "Article",
                        content: `BootstrapVue's tooltips support contextual color variants via our custom CSS.`,
                    },
                    {
                        id: 4,
                        type: "quiz",
                        name: "Quiz",
                        content: `555`,
                    },
                    {
                        id: 2,
                        type: "video",
                        name: "Video",
                        content: `https//google.com.eg`,
                    },
                    // {
                    //     id: 3,
                    //     type: "cme",
                    //     name: "CME‏ Accredited ",
                    //     content: `ootstrapVue's tooltips support contextual color variants via our custom CSS.`,
                    // },
                ],
            },
            isFirstPage: true,
        };
    },
};
</script>
